"use client";

import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import Dashboard from "@/components/Dashboard/Dashboard";

export default function HomeClient() {

  const sessionResult = useSession();
  const session = sessionResult?.data;
  const router = useRouter();

  if (!session) {
    router.push("/auth/signin");
    return null;
  }

  return <Dashboard />;
}
